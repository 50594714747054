<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >Select an image for {{ for_category ? type.name : type }}</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form
          @submit.prevent="saveForm"
          method="post"
          id="image-selection-form"
          v-if="images.length > 0"
        >
          <v-radio-group v-model="image_id">
            <v-row>
              <v-col cols="4" v-for="image in images" v-bind:key="image.id">
                <v-card>
                  <v-img :src="image.asset_urls.thumbnail"></v-img>
                  <v-card-subtitle>
                    <v-radio :label="image.name" :value="image.id"></v-radio>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-form>
        <div v-else>There are no images saved for this category.</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="white">
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="image-selection-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      type: "",
      image_id: null,
      for_category: false,
    };
  },

  computed: {
    images() {
      let images = this.$store.state.drum.admin["images"];

      if (this.type !== "") {
        images = images.filter((i) => {
          let match = false;
          i.categories.forEach((c) => {
            const name = this.for_category ? this.type.name : this.type;
            if (c.name === name) {
              match = true;
            }
          });
          return match;
        });
      }

      return images;
    },
  },

  methods: {
    openDialog(title, image_id = null, for_category = false) {
      this.type = title;
      this.dialog = true;
      this.image_id = image_id;
      this.for_category = for_category;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.type = "";
      this.image_id = null;
      this.for_category = false;
    },

    saveForm() {
      this.loading = true;

      if (this.for_category === true) {
        this.saveForCategory();
      } else {
        this.saveForItinerary();
      }
    },

    saveForCategory() {
      this.$store
        .dispatch("drum/admin/saveImageCategory", {
          appId: this.$route.params.id,
          categoryId: this.type.id,
          fields: {
            image_id: this.image_id,
          },
        })
        .then(() => {
          this.resetForm();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    saveForItinerary() {
      this.$store
        .dispatch("drum/tours/saveItineraryImage", {
          appId: this.$route.params.id,
          tourId: this.$route.params.tourId,
          itineraryId: this.$route.params.itineraryId,
          fields: {
            type: this.type,
            image_id: this.image_id,
          },
        })
        .then(() => {
          this.resetForm();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>